import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

import store from 'store2';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresAuth: false
    },
    component: Home
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Search.vue')
  },
  {
    path: '/search/:id',
    name: 'SearchResult',
    meta: {
      requiresAuth: true,
    },
    props: true,
    component: () => import('@/views/SearchResult.vue')
  },
  {
    path: '/subscription',
    name: 'Subscription',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Subscription.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresAuth: false,
      redirectIfAuth: true,
    },
    component: () => import('@/views/Auth/Login.vue')
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/Auth/ForgetPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/Auth/ResetPassword.vue')
  },
  {
    path: '/member-application',
    name: 'Member Application',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/MemberApplication/index.vue')
  },
  {
    path: '/member-application/success',
    name: 'Successful Member Application',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/MemberApplication/success.vue')
  },
  {
    path: '/member-application/failed',
    name: 'Failed Member Application',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/MemberApplication/failure.vue')
  },
  {
    path: '/member-application/waiting-upload',
    name: 'Waiting Member Application',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/MemberApplication/waitingUpload.vue')
  },
  {
    path: '/member-application/offline-waiting',
    name: 'Waiting Offline',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/MemberApplication/waitingOffline.vue')
  },
  {
    path: '/upload-bank-transfer-receipt/:uuid',
    name: 'Upload Bank Transfer Receipt',
    meta: {
      requiresAuth: false
    },
    props: true,
    component: () => import('@/views/UploadBankTransferReceipt.vue')
  },
  {
    path: '/e-membership-card',
    name: 'e-Membership Card',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/EMembershipCard.vue')
  },
  {
    path: '/email',
    name: 'Email',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Email.vue')
  },
  {
    path: '/*',
    redirect: _to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/' };
    },
    meta: {},
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, _from, next) => {
  if (router.app.$store && router.app.$store.getters['showMobileMenu']) {
    router.app.$store.dispatch('toggleShowMobileMenu');
  }

  let isAuthenticated = false;
  const token = store('token');
  if (token) {
    isAuthenticated = true;
  }

  if (isAuthenticated && router.app.$store) {
    try {
      await router.app.$store.dispatch('reloadMe');
    } catch (err) {
      // console.error(err);
      isAuthenticated = false;
    }
  }

  if (isAuthenticated) {
    if (to.matched.some((record) => record.meta.redirectIfAuth)) {
      return next({
        name: 'Home',
      });
    } else {
      return next();
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      return next({
        name: 'Login',
      });
    }
    return next();
  }
});

export default router;






import Vue from "vue";
export default Vue.extend({
  name: "AppActionButton",
  props: {
    text: {
      type: String,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

require('./register-component');
require('./plugins/firebase');
require('./plugins/vuelidate');
require('./plugins/http');
require('./plugins/recaptcha');

import filters from './filters';
for (const key in filters) {
  const filter = (filters as any)[key];
  Vue.filter(key, filter);
}
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

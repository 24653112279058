export const SHOP_LINK = 'https://shop.lscoba.com/';

export const ERROR_CODES = {
  EMAIL_ALREADY_EXIST: 'EMAIL_ALREADY_EXIST',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_DELETED: 'USER_DELETED',
  OTP_NOT_FOUND: 'OTP_NOT_FOUND',
  METHOD_NOT_IMPLEMENTED: 'METHOD_NOT_IMPLEMENTED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  TX_ALREADY_EXIST: 'TX_ALREADY_EXIST',
  EMAIL_INVALID: 'EMAIL_INVALID',
  MEMBER_ALREADY: 'MEMBER_ALREADY',
  APPLICATION_DUPLICATED: 'APPLICATION_DUPLICATED',
};

export const MOBILE_THRESHOLD = 768;
export const XL_THRESHOLD = 1280;
export const TWOXL_THRESHOLD = 1536;

















import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: String,
    },
    items: {
      type: Array,
    },
    prependInnerIcon: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    itemText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  name: "AppSelect",
  data() {
    return {
      localValue: "",
    };
  },
  created() {
    this.localValue = this.value;
  },
  methods: {
    onInput(event) {
      this.localValue = event;
      this.$emit("input", event);
    },
    onBlur() {
      this.$emit("blur");
    },
  },
  watch: {
    value: function (val, oldVal) {
      if (oldVal !== val) {
        this.localValue = val;
      }
    },
  },
});

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKvOmWY9_y3xaPqHOzqyAe5MBGhwD_aTk",
  authDomain: "lscoba-dev.firebaseapp.com",
  projectId: "lscoba-dev",
  storageBucket: "lscoba-dev.appspot.com",
  messagingSenderId: "28923689651",
  appId: "1:28923689651:web:562e5c9610496fa8230ec6",
  measurementId: "G-JJFKCVGM0K"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

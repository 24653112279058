





import Vue from "vue";

export default Vue.extend({
  name: "App",

  beforeCreate() {
    this.$store.dispatch("initialiseStore");
    this.$store.dispatch("initialiseProfessions");
    this.$store.dispatch("initialiseCountries");
  },
  data: () => ({
    //
  }),
});

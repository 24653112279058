






import Vue from "vue";
import store from "store2";

import { SHOP_LINK } from "@/utils/constants";

export default Vue.extend({
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.whenEntered();
    });
  },
  name: "Home",
  data() {
    return {
      SHOP_LINK,
    };
  },
  methods: {
    async whenEntered() {
      const token = store("token");

      if (!token) {
        this.$router.replace("/login");
      } else {
        this.$router.replace("/e-membership-card");
      }
    },
  },
});

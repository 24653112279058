





































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { vueWindowSizeMixin } from "vue-window-size/option-api";

import { TWOXL_THRESHOLD } from "@/utils/constants";
import { HeaderLink } from "@/components/common/AppHeaderLink.vue";

const DESKTOP_HEADER_HEIGHT = 167;
const MOBILE_HEADER_HEIGHT = 60;
const DESKTOP_LOGO_HEIGHT = 87;
const MOBILE_LOGO_HEIGHT = 41;

const PURPLE_ROUTES = [
  "Login",
  "ForgetPassword",
  "ResetPassword",
  "Upload Bank Transfer Receipt",
];

export default Vue.extend({
  name: "AppHeader",
  computed: {
    ...mapGetters(["user", "showMobileMenu"]),
    headerLinks(): Array<HeaderLink> {
      let results = Array<HeaderLink>();
      if (this.isPurpleHeader) return results;
      if (this.user) {
        results.push({
          name: "E-Membership Card",
          to: "/e-membership-card",
        });
        results.push({
          name: "Edit Profile",
          to: "/profile",
        });
        // results.push({
        //   name: "Email Alias",
        //   to: "/email",
        // });
        results.push({
          name: "Member Search",
          to: "/search",
        });
        // results.push({
        //   name: "Subscription",
        //   to: "/subscription",
        // });
        results.push({
          name: "Logout",
          action: this.signOut,
        });
      } else {
        results.push({
          name: "Login",
          to: "/login",
        });
        results.push({
          name: "Member Application",
          to: "/member-application",
        });
      }

      return results;
    },
    headerHeight(): number {
      return this.$windowWidth > TWOXL_THRESHOLD
        ? DESKTOP_HEADER_HEIGHT
        : MOBILE_HEADER_HEIGHT;
    },
    headerLogoHeight(): number {
      return this.$windowWidth > TWOXL_THRESHOLD
        ? DESKTOP_LOGO_HEIGHT
        : MOBILE_LOGO_HEIGHT;
    },
    headerLogoWidth(): number {
      return (this.headerLogoHeight * 1386) / 262;
    },
    isPurpleHeader(): boolean {
      return PURPLE_ROUTES.includes(this.$route.name as string);
    },
    headerColor(): string {
      return this.isPurpleHeader ? "#510c76" : "white";
    },
    headerImage(): string {
      return this.isPurpleHeader
        ? "https://firebasestorage.googleapis.com/v0/b/lscoba-dev.appspot.com/o/website_images%2FLSCOBA_logowhite.png?alt=media&token=f807a48e-de7d-438b-918b-31578b2c9324"
        : "https://firebasestorage.googleapis.com/v0/b/lscoba-dev.appspot.com/o/website_images%2FLSCOBA_logoblack.png?alt=media&token=2bf26e08-0bd9-4beb-a70c-be60a625db95";
    },
  },
  data() {
    return {
      // showMobileMenu: false,
    };
  },
  mixins: [vueWindowSizeMixin()],
  methods: {
    ...mapActions(["logout", "toggleShowMobileMenu"]),
    async signOut() {
      await this.logout();

      this.$router.push({
        name: "Home",
      });
    },
  },
});




































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import store from "store2";

import {
  getPrivacyPolicy,
  getPrivacyPolicyConsent,
  updatePrivacyPolicyConsent,
} from "@/utils/backend";

declare module "vue/types/vue" {
  interface Vue {
    getMe: () => void;
    reloadMe: () => Promise<void>;
    setToken: (token: string) => Promise<void>;
    displayPrivacyPolicyIfNeeded: () => Promise<void>;
    privacyDialog: boolean;
    privacy: any;
  }
}

export default Vue.extend({
  data() {
    return {
      privacyDialog: false,
      privacy: {},
    };
  },
  computed: {
    ...mapGetters(["message", "loading", "user"]),
    showSnackbar() {
      return this.message && this.message.length;
    },
  },
  methods: {
    ...mapActions(["setToken", "reloadMe"]),
    async getMe(): Promise<void> {
      const orgToken = store("token");
      try {
        const token = this.$route.query["access-token"] as string;
        if (token) {
          await this.setToken(token);
          // store("token", token);
          // await this.reloadMe();
          this.$router.replace({ query: {} });
        }

        await this.reloadMe();
      } catch (err) {
        if (orgToken) {
          store.remove("token");
          this.$router.replace("/login");
        }
      }
    },
    async displayPrivacyPolicyIfNeeded(): Promise<void> {
      try {
        const policy = await getPrivacyPolicy();
        this.privacy = policy;
        if (this.user) {
          try {
            const consent = await getPrivacyPolicyConsent(policy.id);
            const existingPrivacyPolicyId = consent.privacy_policy_id;
            if (existingPrivacyPolicyId + "" !== policy.id + "") {
              this.privacyDialog = true;
            }
          } catch (err) {
            this.privacyDialog = true;
          }
          // TODO: logged in user privacy consent handling
        } else {
          const existingPrivacyPolicyId = store("privacy");
          if (existingPrivacyPolicyId + "" !== policy.id + "") {
            this.privacyDialog = true;
          }
        }
      } catch (err) {
        // store.remove("token");
      }
    },
    async agreePrivacyPolicy(): Promise<void> {
      try {
        if (this.user) {
          // TODO: logged in user privacy consent handling
          await updatePrivacyPolicyConsent();
        } else {
          store("privacy", this.privacy.id);
        }

        this.privacyDialog = false;
      } catch (err) {
        // store.remove("token");
      }
    },
  },
  async mounted() {
    await this.getMe();

    await this.displayPrivacyPolicyIfNeeded();
  },
});

import { ERROR_CODES } from '@/utils/constants';

export const displayError = (code: string | undefined): string => {
  switch (code) {
    case ERROR_CODES.USER_NOT_FOUND:
      return 'Your email address and password do not match. If you forgot your password please use the Forget Password to reset the password.';
    case ERROR_CODES.USER_DELETED:
      return 'Your account has been deleted. Please contact the administrator in case of any enquiry.';
    case ERROR_CODES.OTP_NOT_FOUND:
      return 'The Reset Password link was expired. If you forgot your password please use the Forget Password to reset the password again.';
    case ERROR_CODES.MEMBER_ALREADY:
      return 'You are one of the members already. Please login the system directly.';
    case ERROR_CODES.APPLICATION_DUPLICATED:
      return 'You have submitted an application already. Please contact the administrator in case of any enquiry.';
    case 'FORM_INVALID':
      return 'The form you filled in is invalid, please check for the invalid fields, correct them and try again.';
    case ERROR_CODES.EMAIL_INVALID:
      return 'Your email address is invalid, please try again with a valid email address.';
    default:
      break;
  }


  return 'Network Error occured, please try again later.';
};
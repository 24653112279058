import axios from 'axios';

axios.defaults.baseURL =
  process.env.VUE_APP_BASE_URL;

axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject({ code: 'COMMON_ERROR' });
  }
);
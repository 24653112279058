














import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: String,
    },
    height: {
      type: String,
      default: "18px",
    },
  },
  name: "AppTooltip",
  data: () => {
    return {
      show: false,
    };
  },
});















import Vue from "vue";

export interface HeaderLink {
  name: string;
  to?: string;
  action?: any;
  href?: string;
}

export default Vue.extend({
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  name: "AppHeaderLink",
  data() {
    return {};
  },
  methods: {
    onClick(event) {
      if (this.link.action) {
        this.link.action(event);
      }
    },
  },
});

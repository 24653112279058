




import Vue from "vue";

export default Vue.extend({
  props: {
    backgroundColor: {
      type: String,
      default: "#f6f3fb",
    },
    top: {
      type: String,
      default: "482px",
    },
  },
  name: "AppBackground",
  data() {
    return {};
  },
});

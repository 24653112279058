import axios from 'axios';

export const searchUsers = async (params: any) => {
  const response: any = await axios.get('/apis/users/search', {
    params,
  });

  return response.results;
};

export const getSearchUser = async (id: number | string) => {
  const response: any = await axios.get(`/apis/users/search/${id}`);

  return response.result;
};

export const login = async (
  email: string,
  password: string,
  gRecaptchaResponse: string
): Promise<any> => {
  const response: any = await axios.post('/apis/v2/auth/login', {
    email,
    password,
    gRecaptchaResponse,
  });

  return response.result;
};

export const logout = async (): Promise<any> => {
  const response: any = await axios.post('/apis/auth/logout');

  return response.result;
};

export const getMe = async (): Promise<any> => {
  const response: any = await axios.get('/apis/users/me');

  return response.result;
};

export const getFullProfile = async (): Promise<any> => {
  const response: any = await axios.get('/apis/users/me/profile');

  return response.result;
};

export const updateFullProfile = async (params: any): Promise<any> => {
  const response: any = await axios.put('/apis/users/me/profile', {
    ...params,
  });

  return response.result;
};

export const forgetPassword = async (
  email: string,
  gRecaptchaResponse: string
): Promise<any> => {
  const response: any = await axios.post('/apis/v2/auth/forget', {
    email,
    gRecaptchaResponse,
  });

  return response.result;
};

export const resetPassword = async (
  otp: string,
  password: string
): Promise<any> => {
  const response: any = await axios.post('/apis/auth/reset', {
    otp,
    password,
  });

  return response.result;
};

export const createMemberApplication = async (params: any): Promise<any> => {
  const response: any = await axios.post('/apis/members/application', {
    ...params,
  });

  return response.result;
};

export const listProfessions = async (): Promise<any> => {
  const response: any = await axios.get('/apis/professions');

  return response.results;
};

export const listCountries = async (): Promise<any> => {
  const response: any = await axios.get('/apis/countries');

  return response.results;
};

export const getPrivacyPolicy = async (): Promise<any> => {
  const response: any = await axios.get('/apis/privacy');

  return response.result;
};

export const getPrivacyPolicyConsent = async (
  policy_id: string
): Promise<any> => {
  const response: any = await axios.get(`/apis/privacy/${policy_id}/consent`);

  return response.result;
};

export const updatePrivacyPolicyConsent = async (): Promise<any> => {
  const response: any = await axios.put(`/apis/privacy`);

  return response.result;
};

export const getYearMembershipFee = async (id: string): Promise<any> => {
  const response: any = await axios.get(`/apis/applications/fees/${id}`);

  return response.result;
};

export const getMemberApplication = async (uuid: string): Promise<any> => {
  const response: any = await axios.get(`/apis/applications/${uuid}`);

  return response.result;
};

export const submitBankTransferReceiptForMemberApplication = async (
  uuid: string,
  path: string
): Promise<any> => {
  const response: any = await axios.put(`/apis/applications/${uuid}`, {
    image_path: path,
  });

  return response.result;
};

export const listSubscriptionOptions = async (): Promise<any> => {
  const response: any = await axios.get(`/apis/users/me/subscriptions/options`);

  return response.results;
};

export const updateSubscriptionOption = async (id, params): Promise<any> => {
  const response: any = await axios.put(
    `/apis/users/me/subscriptions/options/${id}`,
    {
      ...params,
    }
  );

  return response.result;
};

export const getAddToWalletUrl = async (): Promise<any> => {
  const response: any = await axios.get(`/apis/users/me/add_to_wallet`);

  return response.result;
};








import Vue from "vue";

export default Vue.extend({
  props: {
    label: { type: String },
    value: {
      type: Boolean,
    },
  },
  name: "AppCheckbox",
  data() {
    return {
      localValue: false,
    };
  },
  created() {
    this.localValue = this.value;
  },
  watch: {
    localValue: function (val) {
      this.$emit("input", val);
    },
    value: function (val, oldVal) {
      if (oldVal !== val) {
        this.localValue = val;
      }
    },
  },
});

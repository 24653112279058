import Vue from 'vue';
import Vuex from 'vuex';
import store from 'store2';
import axios from 'axios';

Vue.use(Vuex);

import { login, logout, getMe, resetPassword } from "@/utils/backend";
import { displayError } from '@/utils/message';

export default {
  state: {
    user: undefined,
    token: null,
  },
  mutations: {
    setUser(state: any, user: any): void {
      state.user = user;
    },
    setToken(state: any, token: string | null): void {
      state.token = token;

      axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
    },
  },
  actions: {
    setUser({ commit }: { commit: any; }, user: any): void {
      commit('setUser', user);
    },
    setToken({ commit }: { commit: any; }, token: string): void {
      commit('setToken', token);
    },
    async login({ commit, dispatch }: { commit: any; dispatch: any; }, { email, password, gRecaptchaResponse }: { email: string; password: string; gRecaptchaResponse: string; }): Promise<void> {
      try {
        const result: { token: string; profile: any; } = await login(email, password, gRecaptchaResponse);

        const { token, profile } = result;

        store('user', profile);
        store('token', token);

        commit('setUser', profile);
        commit('setToken', token);
      } catch (err: any) {
        const code = err.code || err.error.code;
        dispatch('displayMessage', displayError(code));
        throw err;
      }

    },
    async resetPassword({ commit, dispatch }: { commit: any; dispatch: any; }, { otp, password }: { otp: string; password: string; }): Promise<void> {
      try {
        const result: { token: string; profile: any; } = await resetPassword(otp, password);

        const { token, profile } = result;

        store('user', profile);
        store('token', token);

        commit('setUser', profile);
        commit('setToken', token);
      } catch (err) {
        const code: string | undefined = (err != undefined && (err as any).code !== undefined) ? (err as any).code : undefined;
        dispatch('displayMessage', displayError(code));
        throw err;
      }

    },
    async logout({ commit }: { commit: any; }): Promise<void> {
      try {
        await logout();
        // eslint-disable-next-line no-empty
      } catch (err) { }

      store.remove('user');
      store.remove('token');

      commit('setUser', undefined);
      commit('setToken', null);
    },
    async reloadMe({ state, commit, dispatch }: { state: any; commit: any; dispatch: any; }): Promise<any> {
      try {
        const token = state.token;

        const user = await getMe();
        commit('setUser', user);

        store('user', user);
        store('token', token);
      } catch (err) {
        dispatch('logout');
        throw err;
      }
    },
  },
  getters: {
    user: (state: any): any => state.user,
    token: (state: any): any => state.token,
  },
};

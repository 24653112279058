



































import Vue from "vue";

export default Vue.extend({
  props: {
    errorMessages: {
      type: Array,
    },
    label: { type: String },
    labelPrefixText: { type: String },
    placeholder: { type: String },
    required: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
    showAsterisk: {
      type: Boolean,
      default: false,
    },
  },
  name: "AppTextField",
  data() {
    return {
      localValue: "",
    };
  },
  created() {
    this.localValue = this.value;
  },
  methods: {
    onBlur() {
      this.$emit("blur");
    },
    onInput(event) {
      this.localValue = event.target.value;
    },
    onClick() {
      this.$emit("click");
    },
  },
  watch: {
    localValue: function (val) {
      this.$emit("input", val);
    },
    value: function (val, oldVal) {
      if (oldVal !== val) {
        this.localValue = val;
      }
    },
  },
});





























import Vue from "vue";

export default Vue.extend({
  props: {
    items: Array,
    headers: Array,
    rowClicked: Function,
    showExpand: {
      type: Boolean,
      default: false,
    },
    search: String,
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  name: "AppTable",
  data() {
    return {
      footerProps: {
        "items-per-page-options": [10, 50, 100],
      },
      expanded: [],
      singleExpand: false,
      selected: [],
    };
  },
  methods: {
    clickRow(item: unknown) {
      if (this.rowClicked) {
        this.rowClicked(item);
      }
    },
  },
  watch: {
    selected(newValue, _) {
      this.$emit("selected", newValue);
    },
  },
});

import Vue from 'vue';
import Vuex from 'vuex';
import store from 'store2';

Vue.use(Vuex);

import auth from './auth';
import { listProfessions, listCountries } from "@/utils/backend";

export default new Vuex.Store({
  state: {
    message: '',
    loading: false,
    showMobileMenu: false,
    professions: new Array<any>(),
    countries: new Array<any>(),
  },
  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setShowMobileMenu(state, showMobileMenu: boolean) {
      state.showMobileMenu = showMobileMenu;
    },
    setProfessions(state, professions) {
      state.professions = [...professions];
    },
    setCountries(state, countries) {
      state.countries = [...countries];
    },
  },
  actions: {
    displayMessage({ commit }, message) {
      commit('setMessage', message);

      const timer = setTimeout(() => {
        clearTimeout(timer);
        commit('setMessage', '');
      }, 5000);
    },
    showLoading({ commit }) {
      commit('setLoading', true);

      const timer = setTimeout(() => {
        clearTimeout(timer);
        commit('setLoading', false);
      }, 30000);
    },
    hideLoading({ commit }) {
      commit('setLoading', false);
    },
    initialiseStore({ commit, dispatch }) {
      const user = store('user');
      commit('setUser', user);

      const token = store('token');
      commit('setToken', token);

      if (token) {
        dispatch('reloadMe');
      }
    },
    toggleShowMobileMenu({ commit, state }) {
      commit('setShowMobileMenu', !state.showMobileMenu);
    },
    async initialiseProfessions({ commit }) {
      const results = await listProfessions();

      commit('setProfessions', results);
    },
    async initialiseCountries({ commit }) {
      const results = await listCountries();

      commit('setCountries', results);
    },
  },
  getters: {
    message: (state) => state.message,
    loading: (state) => state.loading,
    showMobileMenu: (state) => state.showMobileMenu,
    professions: (state) => state.professions,
    countries: (state) => state.countries,
  },
  modules: {
    auth
  }
});

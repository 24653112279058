import { DateTime } from 'luxon';

const fullname = (item: any): string => {
  switch (item.name_order) {
    case 'G':
      return `${item.first_name_en} ${item.last_name_en}`;
    case 'F':
    default:
      return `${item.last_name_en} ${item.first_name_en}`;

  }
};

const dateTime: (a: any) => string = (date: any): string => {
  if (!date) return 'N/A';

  let datetime: DateTime;
  if (date instanceof Date) {
    datetime = DateTime.fromJSDate(date);
  } else {
    datetime = DateTime.fromISO(date);
  }

  return datetime.toLocaleString(DateTime.DATETIME_SHORT);
};


export default {
  fullname,
  dateTime,
};

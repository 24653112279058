













import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    returnText: {
      type: String,
    },
    returnAction: {},
  },
  name: "AppStaticLayout",
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
